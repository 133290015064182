.container{
    display: flex;
    flex-direction: column;
    margin: auto;
    margin-top: 80px;
    background: #fff;
    padding-bottom: 30px;
    width: 600px;
}
.container2{
    display: flex;
    flex-direction: column;
    align-items: self-center;
    justify-content: center;
    margin: auto;
    margin-top: 80px;
    background: #fff;
    width: 900px;
}
.header{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 9px;
    width: 100%;
    margin-top: 30px;
}

.text{
    color: #3c009d;
    font-size: 48px;
    font-weight: 700;
}

.underline{
    width: 61px;
    height: 6px;
    background: #3c009d;
    border-radius: 9px;
}

.inputs{
    margin-top: 55px;
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.input{
    display: flex;
    align-items: center;
    margin: auto;
    width: 480px;
    height: 80px;
    background: #eaeaea;
    border-radius: 6px;
}

.input img{
    margin: 30px;
}

.input input{
    height: 50px;
    width: 400px;
    background: transparent;
    border: none;
    outline: none;
    color: #797979;
    font-size: 19px;
}

.forgot-password{
    padding-left: 315px;
    margin-top: 27px;
    color: #797979;
    font-size: 18px;
}

.forgot-password span{
    color: #4c00b4;
    cursor: pointer;
}

.submit-container{
    display: flex;
    gap: 30px;
    margin: 60px auto;
}

.submit{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 59px;
    color: #fff;
    background: #4c00b4;
    border-radius: 50px;
    font-size: 19px;
    font-weight: 700;
    cursor: pointer;
}

.gray{
    background: #EAEAEA;
    color: #676767;
}